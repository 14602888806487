<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <DataTable
          :cols="cols"
          :filter-keys="filterKeys"
          :request-params="{ with: ['createdBy', 'bike', 'booking'] }"
          default-order-by="created_at"
          path="payments"
        >
          <template v-slot:item.created_by="{ item }">
            {{ item.created_by ? item.created_by.name : '-' }}
            <v-avatar
              v-if="item.created_by"
              color="primary"
              size="20"
            >
              <img
                v-if="item.created_by.profile_img_url"
                :src="item.created_by.profile_img_url"
                :alt="item.created_by.name">
              <strong v-else class="white--text" style="font-size: 10px;">{{ item.created_by.initials }}</strong>
            </v-avatar>
          </template>
          <template v-slot:item.bike="{ item }">
            {{ item.bike ? item.bike.ref : '-' }}
          </template>
          <template v-slot:item.booking="{ item }">
            <router-link :to="{ name: 'bookings.show', params: { bookingId: item.booking.id } }">
              {{ item.booking.customer_name }}
            </router-link>
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ $momentDateTimeHuman(item.created_at) }}
          </template>
          <template v-slot:item.amount="{ item }">
            {{ $currency(item.amount) }}
          </template>
          <template v-slot:item.refund="{ item }">
            <v-icon
              v-if="item.refund"
              color="green"
              small
              dense
            >
              mdi-check-circle
            </v-icon>
            <span></span>
          </template>
        </DataTable>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DataTable from '@/components/DataTable'

export default {
  name: 'Payments',

  components: {
    DataTable
  },

  data () {
    return {
      cols: [
        { text: 'Created By', value: 'created_by' },
        { text: 'Amount', value: 'amount' },
        { text: 'Bike', value: 'bike' },
        { text: 'Booking', value: 'booking' },
        { text: 'Description', value: 'description' },
        { text: 'Refund', value: 'refund' },
        { text: 'Created At', value: 'created_at' },
      ]
    }
  },

  computed: {
    filterKeys () {
      return [
        { key: 'refund', 'label': 'Refund', type: 'Select', values: [{ label: 'True', value: true }, { label: 'False', value: false }]}
      ]
    }
  }
}
</script>
